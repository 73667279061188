/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-string-refs */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import toast from 'react-hot-toast';
import { postConcernHandler } from '../service';
import { strings } from '../utils/localizedStrings';

class UpdateContact extends Component {
  constructor() {
    super();
    this.state = {
      isSubmit: false,
      contactNumber: null
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (!this.props.tracking.config.isUpdateContact) {
      this.goBack();
    }
  }

  goBack = () => {
    const { routePrefix } = this.props.user;
    this.props.history.push(
      `${routePrefix ? `/${routePrefix}` : ''}/${this.props.history.location
        .search + this.props.history.location.hash}`
    );
  };

  changeValue(e) {
    this.setState({ contactNumber: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const phonePattern = new RegExp(/^\d{10}$/);
    const { tracking, user, page } = this.props;
    const { lang } = user;
    const phone_number = this.state.contactNumber;
    if (!phonePattern.test(phone_number)) {
      return toast.error(strings[lang].phoneErr);
    }
    const query = {
      issue_id: 4,
      phone_number,
      intent: 'Update contact detail(InTransit Shipment)'
    };
    if (page.source) {
      query.feedback_source = page.source;
    }
    const { search, hash } = this.props.location;
    const searchParams = new URLSearchParams(search + hash);

    query.security_key =
      searchParams.get('security_key') || searchParams.get('key');
    this.setState({ isSubmit: true });
    const toastId = toast.loading(strings[lang].actionInProgress);
    postConcernHandler(tracking.trackingId, false, query).then(res => {
      if (res.meta.success) {
        toast.success(strings[lang].updateContactToastr, { id: toastId });
        this.setState({ isSubmit: false });
        this.goBack();
      } else {
        this.setState({ isSubmit: false });
        toast.error(res.meta.message, { id: toastId });
      }
    });
  }

  render() {
    const { tracking, user } = this.props;
    const { routePrefix, lang } = user;
    return !tracking.isFetching ? (
      <div
        className={`container-fluid pd-top-40 xs-pd-top-10 pd-btm-40 xs-pd-btm-10 text-left main-container
        ${user.subdomain == '6thstreet' ? 'sixthstreet' : ''}
        ${user.subdomain == '6thstreet-test' ? 'sixthstreet-test' : ''}
        ${user.subdomain == '21kschool' ? 'twentyonekschool' : ''}
        ${
          user.subdomain == '1mg' || user.subdomain == '1mg-test'
            ? 'onemg'
            : user.subdomain
        }`}
      >
        <div className="row col-gutter-130 xs-pd-left-10 xs-pd-right-10">
          <div className="col-md-8 pd-left-20  pd-right-20 xs-pd-0 col-xs-24">
            <div className="bg-white hidden-xs mrg-btm-20">
              <div className="order-info pd-20">
                <div className="header">
                  <img
                    className="mrg-right-10 xs-display-in-block"
                    src="/images/order-icon.svg"
                    alt="order icon"
                  />
                  <span>{strings[lang].orderDetails}</span>
                </div>
                <div className="detailed">
                  <p className="mrg-top-10">
                    <label>{strings[lang].courier}: </label>
                    <span className="highlight">{tracking.courier.name}</span>
                  </p>
                  <div className="row mrg-top-10 xs-mrg-top-5">
                    <div className="col-md-24 col-xs-24 pd-left-15 pd-right-15 xs-mrg-btm-5">
                      <label>{strings[lang].trackingId}: </label>{' '}
                      <span className="text user-select">
                        {tracking.waybill}
                      </span>
                    </div>
                    <div className="col-md-24 col-xs-24 pd-left-15 pd-right-15 mrg-top-10">
                      <label>{strings[lang].orderId}: </label>{' '}
                      <span className="text user-select">
                        {tracking.orderId ? tracking.orderId : 'NA'}
                      </span>
                    </div>
                  </div>
                  <div className="row mrg-top-10 xs-mrg-top-5">
                    <div className="col-md-24 col-xs-24 pd-left-15 pd-right-15">
                      <p>
                        <span className="rectangle" />
                        <label className="vivid-orange  pull-left">
                          {strings[lang].status}:{' '}
                        </label>
                        <span className="text user-select">
                          <strong
                            style={{ color: tracking.order.timelineColor }}
                          >
                            {strings[lang].statusOnDateFn(
                              tracking.latestStatus.text,
                              moment(
                                tracking.latestStatus.timestamp.slice(0, 10)
                              ).format('Do MMM YY')
                            )}
                          </strong>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="track-order xs-mrg-btm-10">
              <NavLink
                to={`${routePrefix ? `/${routePrefix}` : ''}/${
                  this.props.history.location.search
                }`}
              >
                {strings[lang].viewTracking}
              </NavLink>
            </div>
          </div>

          <div className="col-md-16 col-xs-24 view-box pd-0">
            <div className="header">
              <div className="text-left">
                <p className="pos-rel xs-pd-left-10">
                  {strings[lang].updateContactNumber}
                  <span
                    className="icon-arrow_back pull-left"
                    role="button"
                    tabIndex="0"
                    onClick={e => this.goBack(e)}
                  />
                </p>
              </div>
            </div>
            <div className="content">
              <p>{strings[lang].updateContactNumberToastr}</p>
              <form className="form mrg-top-40">
                <div id="phone-block" className="inputGroup left">
                  <label className="text">{strings[lang].contactNumber}</label>
                  <input
                    id="phone_number"
                    type="text"
                    className="input-text"
                    onChange={e => this.changeValue(e)}
                    value={this.state.contactNumber || ''}
                  />
                </div>
                <div className="text-center mrg-top-20 mrg-btm-10">
                  <button className="button" onClick={this.handleSubmit}>
                    {strings[lang].submit}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}

UpdateContact.contextTypes = {
  router: PropTypes.object.isRequired
};

UpdateContact.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  page: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user,
  page: state.page
});

export default withRouter(connect(mapStateToProps, null)(UpdateContact));
